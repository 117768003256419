import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "松江で再びサテライトオフィス体験してきました",
  "date": "2018-09-28T04:58:38.000Z",
  "slug": "entry/2018/09/28/135838",
  "tags": ["medley"],
  "hero": "./2018_09_28.png",
  "heroAlt": "matsue"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`お疲れ様です。開発本部の宍戸です。`}</p>
    <p>{`9/11〜9/14 まで、`}<a parentName="p" {...{
        "href": "/entry/2017/05/23/171859"
      }}>{`昨年もお世話になった`}</a>{`松江市でお試しサテライトオフィス勤務を行ってきました。`}</p>
    <p>{`昨年度は`}<a parentName="p" {...{
        "href": "https://www.soumu.go.jp/satellite-office/"
      }}>{`総務省のサテライトオフィス事業`}</a>{`を利用する形でしたが、今年は`}<a parentName="p" {...{
        "href": "https://www1.city.matsue.shimane.jp/jigyousha/sangyou/kigyou/otameshi.html"
      }}>{`松江市独自のプロジェクト`}</a>{`として実施されるとのことで再度松江市さんからお声がけをいただき、開発本部から 3 名でサテライトオフィス勤務をしてきましたので、その様子をレポートします。`}</p>
    <h1>{`オフィスの様子など`}</h1>
    <p>{`今回のサテライトオフィス勤務は、全日程を`}<a parentName="p" {...{
        "href": "https://www.sanbg.com/terrsa/outline/index.html"
      }}>{`松江テルサ別館`}</a>{`で行いました。昨年もお世話になったこちらの施設ですが、開発に必要なものは基本的にすべて揃っているので、到着してすぐに作業することができました。（なぜ真ん中の卓に全員座らなかったのか、仲悪すぎだろと言われたりしますが決してそういうわけではありません（真顔））`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/L/Layzie/20180928/20180928172848.jpg",
        "alt": "f:id:Layzie:20180928172848j:plain",
        "title": "f:id:Layzie:20180928172848j:plain"
      }}></img></p>
    <p>{`（写真は、今回アテンドしてくださった`}<a parentName="p" {...{
        "href": "https://www.facebook.com/photo.php?fbid=731347827201388&set=a.304643019871873&type=3&theater"
      }}>{`松江市の土江さん`}</a>{`の許可をいただき使わせていただきました）`}</p>
    <p>{`こちら松江テルサ別館には、`}<a parentName="p" {...{
        "href": "https://www1.city.matsue.shimane.jp/jigyousha/sangyou/ruby/rabo_open.html"
      }}>{`松江オープンソースラボ`}</a>{`という OSS に関する作業や交流のために提供される施設があります。今回伺ったタイミングでは、このラボのエリアは、区画を広げるべく現在改装中でした。`}</p>
    <p>{`話を伺うと、`}<strong parentName="p">{`松江市にサテライトオフィスを構える Web 系企業も徐々に増えつつある`}</strong>{`ようで、現地での勉強会の開催などに利用しやすいよう今回の改装を行っているとのこと。（大部屋として使ったり、分割して使ったりなどなどできるようにしているそうです）`}</p>
    <p>{`働く環境だけでなく、コミュニティ支援についても市が積極的にサポートしていく雰囲気を伺い知ることができ、あらためて`}<strong parentName="p">{`Ruby City としての気概`}</strong>{`を感じました。`}</p>
    <h1>{`松江の雰囲気など`}</h1>
    <p>{`松江への到着は初日のお昼頃でしたので、まずは前回も伺った`}<a parentName="p" {...{
        "href": "https://tabelog.com/shimane/A3201/A320101/32000017/"
      }}>{`八雲庵`}</a>{`さんでお蕎麦をいただきました。松江城のお堀のすぐ近くにあり、歴史ある雰囲気が印象的でした。割子そばという、何段かに分けられたそばに直接つゆを注いで食べるスタイルが、こちらのメジャーな食べ方とのこと。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/L/Layzie/20180928/20180928172901.jpg",
        "alt": "f:id:Layzie:20180928172901j:plain",
        "title": "f:id:Layzie:20180928172901j:plain"
      }}></img></p>
    <p>{`お昼を食べた後、再びサテライトオフィスで開発をした後は、前回も伺った現地の居酒屋、`}<a parentName="p" {...{
        "href": "https://tabelog.com/shimane/A3201/A320101/32000087/"
      }}>{`根っこや`}</a>{`さんで夕食をいただきました。`}</p>
    <p>{`今回もコーディネーターの方のオススメで地酒の「`}<a parentName="p" {...{
        "href": "https://www.ouroku.com/"
      }}>{`王祿の渓`}</a>{`」をいただきました。期間中はいくつかのお店を回りましたが、日本海の魚から宍道湖のしじみ、そば、地酒などなど、美味しいものに困らない土地だなーという印象が強く、ごはん（とお酒）大好き人間の自分には魅力的なものばかりでした。`}</p>
    <p>{`前回お邪魔した古民家風オフィス「`}<a parentName="p" {...{
        "href": "https://www.soumu.go.jp/satellite-office/shimane/post1.html"
      }}>{`松江城下`}</a>{`」なども、お昼休憩の際に簡単に案内していただきましたが、すでにこちらは`}<strong parentName="p">{`サテライトオフィスとして企業が契約をし、稼働を開始している`}</strong>{`とのこと。（個人的にはちょっと中を覗いてみたかったので残念...）`}</p>
    <p>{`松江城を囲むお堀も観光スポットの一つとのことで、最終日には松江城と共にこちらも体験させていただきました。仕事の疲れを史跡巡りで癒やされに来るのも良さそうです。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/L/Layzie/20180914/20180914101033.jpg",
        "alt": "f:id:Layzie:20180914101033j:plain",
        "title": "f:id:Layzie:20180914101033j:plain"
      }}></img></p>
    <h1>{`定番のお参り`}</h1>
    <p>{`最終日には、プロダクトの成功祈願も兼ねて出雲大社にお参りに行ってきました。（出雲大社は医療の神様とも言われる`}<a parentName="p" {...{
        "href": "https://www.izumooyashiro.or.jp/about/ookami"
      }}>{`大国主命`}</a>{`をお祀りしており、その出自は`}<a parentName="p" {...{
        "href": "https://www.izumooyashiro.or.jp/about/inaba"
      }}>{`因幡の白うさぎ`}</a>{`という古事記の一節とのこと。不勉強にてこのタイミングで知りました・・・）`}</p>
    <p>{`当日はあいにくのお天気でしたが、雨の中濡れる静かな出雲大社も厳かな雰囲気があり、パワーを沢山いただいてきました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/L/Layzie/20180914/20180914134135.jpg",
        "alt": "f:id:Layzie:20180914134135j:plain",
        "title": "f:id:Layzie:20180914134135j:plain"
      }}></img></p>
    <h1>{`まとめ`}</h1>
    <p>{`以上、簡単ではありますが、松江市でのサテライトオフィスお試し勤務の様子を振り返ってみました。`}</p>
    <p>{`昨年のブログでも書かれていますが、`}<strong parentName="p">{`思ったよりも都内からのアクセスが良い`}</strong>{`なと思いましたし、街自体もおちついて静かなので（テルサ別館が駅前にあるのに、日中は電車の音くらいしか気にならない）、非常に集中して作業することができました。`}</p>
    <p>{`また普段一緒に机を並べて作業しているチームから一時的とはいえ離れて作業をしてみて、`}<strong parentName="p">{`隔離された環境での集中しやすさを得た一方、やはり面と向かってメンバーと話したほうが円滑なコミュニケーション取れる`}</strong>{`なということも改めて実感しました。（実際にこの期間中、東京のメンバーに色々動いてもらっていたのに、自分はリモートだったので、もどかしく感じる部分もありました・・・💦）`}</p>
    <p>{`短期間ですが、個人ではなくスモールチームでリモートワークをしてみて良いところ、対面のほうが良いところを実際に把握できたのも収穫でした。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`今回のお試し勤務は、松江市役所の土江さんにコーディネートいただき、実現することができました。`}</p>
    <p>{`スケジュール等々きっちり準備頂いたおかげもあり、滞りなく業務を行うことができましたし、ただただオフィスに籠もって開発をしていただけではきっとわからなかった、松江という場所の雰囲気なども知ることができました。土江さんをはじめ、今回お世話になりました皆様、改めて本当にありがとうございました！`}</p>
    <p>{`ということで、簡単ではありますが、第二回松江市でのサテライトオフィスお試し勤務のレポートでした。`}</p>
    <p>{`メドレーでは、エンジニア・デザイナーを絶賛募集中です。ご興味ある方は、こちらからぜひご連絡ください。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="株式会社メドレーの会社情報 - Wantedly" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.wantedly.com%2Fcompanies%2Fmedley" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.wantedly.com/companies/medley">www.wantedly.com</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      